@import 'src/themes/default/default';

.custom-link {
    &__link {
        color: @text-complement-light;
        text-decoration: underline;

        &_loading {
            cursor: default;
            color: @gray-500;
        }
    }

    &__default {
        color: @base-dark;
    }

    &__kebab-action {
        color: @base-dark;
        text-decoration: none;
    }

  &__button {
    &:focus:not(:hover) {
      background-color: transparent;
      color: #587EAD;
      border: 1px solid #587EAD;
    }

    >.anticon {
      line-height: 0.8;
    }

    svg {
      margin-left: 0px;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";