.actions-settings-modal {
  &__form {
    .form-list {
      &__header {
        display: flex;
        align-items: center;
        gap: 16px
      }

      &__items {
        .ant-checkbox-wrapper {
          margin-top: 16px;
        }
      }
    }
  }

  .form-flex .form-flex-row {
    align-items: flex-start;

    .isActive-select {
      flex: 0 0 auto;
      width: 100px;
    }

    .delete-row-button {
      margin-top: 29px;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";