.button-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 2px;
  height: 36px;
  padding: 9px 14px;
  color: white;
  background-color: #587EAD;
  border: 1.5px solid #B7C1D4;
  box-shadow: 0 1px 2px #E2E6EA;
  border-radius: 5px;
  transition: color;
}
.button-primary > div {
  display: flex;
  align-items: center;
}
.button-primary > svg:first-child:not(:last-child),
.button-primary > span:first-child:not(:last-child),
.button-primary > .action-icon:first-child:not(:last-child) {
  margin-left: -2px;
}
.button-primary > svg + span,
.button-primary > .anticon + span,
.button-primary .action-icon + span {
  margin-left: 6px !important;
}
.button-primary:last-child {
  text-align: center;
}
.button-primary:active,
.button-primary:focus {
  color: #FFFFFF;
  background-color: #587EAD;
}
.button-primary:active svg,
.button-primary:focus svg {
  fill: #FFFFFF;
}
.button-primary:active svg.stroke-change-color,
.button-primary:focus svg.stroke-change-color {
  fill: none;
  stroke: #FFFFFF;
}
.button-primary:active svg.path-stroke-change-color,
.button-primary:focus svg.path-stroke-change-color {
  fill: none;
}
.button-primary:active svg.path-stroke-change-color path,
.button-primary:focus svg.path-stroke-change-color path {
  stroke: #FFFFFF;
}
.button-primary:hover {
  background: #FFFFFF;
  color: #587EAD;
  border: 1.5px solid #587EAD;
}
.button-primary:hover svg {
  fill: #587EAD;
}
.button-primary:hover svg.stroke-change-color {
  fill: none;
  stroke: #587EAD;
}
.button-primary:hover svg.path-stroke-change-color {
  fill: none;
}
.button-primary:hover svg.path-stroke-change-color path {
  stroke: #587EAD;
}
.button-primary:disabled {
  opacity: 0.5;
  color: #FFFFFF;
  background-color: #587EAD;
}
.button-primary:disabled svg {
  fill: #FFFFFF;
}
.button-primary:disabled svg.stroke-change-color {
  fill: none;
  stroke: #FFFFFF;
}
.button-primary:disabled svg.path-stroke-change-color {
  fill: none;
}
.button-primary:disabled svg.path-stroke-change-color path {
  stroke: #FFFFFF;
}
.button-primary:disabled:active,
.button-primary:disabled:hover {
  color: #FFFFFF;
  background-color: #587EAD;
}
.button-primary:disabled:active svg,
.button-primary:disabled:hover svg {
  fill: #FFFFFF;
}
.button-primary:disabled:active svg.stroke-change-color,
.button-primary:disabled:hover svg.stroke-change-color {
  fill: none;
  stroke: #FFFFFF;
}
.button-primary:disabled:active svg.path-stroke-change-color,
.button-primary:disabled:hover svg.path-stroke-change-color {
  fill: none;
}
.button-primary:disabled:active svg.path-stroke-change-color path,
.button-primary:disabled:hover svg.path-stroke-change-color path {
  stroke: #FFFFFF;
}
.button-no-hover_focus:hover,
.button-no-hover_focus:focus {
  background: #587EAD;
  color: #FFFFFF;
  border-color: #B7C1D4;
  box-shadow: none;
}
.button-filter-default {
  padding: 9px 14px;
  gap: 8px;
  height: 36px;
  background: #FFFFFF;
  border: 1.5px solid #B7C1D4;
  box-shadow: 0 1px 2px rgba(226, 230, 234, 0.8);
  border-radius: 5px;
  font-style: normal;
  font-size: 14px;
  line-height: 18.2px;
  text-align: center;
  color: #B7C1D4;
}
.button-critic {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 2px;
  height: 36px;
  padding: 9px 14px;
  color: white;
  background-color: #587EAD;
  border: 1.5px solid #B7C1D4;
  box-shadow: 0 1px 2px #E2E6EA;
  border-radius: 5px;
  transition: color;
  border: 1.5px solid #ED1C24;
  color: #ED1C24;
  background: #FFFFFF;
}
.button-critic > div {
  display: flex;
  align-items: center;
}
.button-critic > svg:first-child:not(:last-child),
.button-critic > span:first-child:not(:last-child),
.button-critic > .action-icon:first-child:not(:last-child) {
  margin-left: -2px;
}
.button-critic > svg + span,
.button-critic > .anticon + span,
.button-critic .action-icon + span {
  margin-left: 6px !important;
}
.button-critic:last-child {
  text-align: center;
}
.button-critic:active,
.button-critic:focus {
  color: #FFFFFF;
  background-color: #587EAD;
}
.button-critic:active svg,
.button-critic:focus svg {
  fill: #FFFFFF;
}
.button-critic:active svg.stroke-change-color,
.button-critic:focus svg.stroke-change-color {
  fill: none;
  stroke: #FFFFFF;
}
.button-critic:active svg.path-stroke-change-color,
.button-critic:focus svg.path-stroke-change-color {
  fill: none;
}
.button-critic:active svg.path-stroke-change-color path,
.button-critic:focus svg.path-stroke-change-color path {
  stroke: #FFFFFF;
}
.button-critic:hover {
  background: #FFFFFF;
  color: #587EAD;
  border: 1.5px solid #587EAD;
}
.button-critic:hover svg {
  fill: #587EAD;
}
.button-critic:hover svg.stroke-change-color {
  fill: none;
  stroke: #587EAD;
}
.button-critic:hover svg.path-stroke-change-color {
  fill: none;
}
.button-critic:hover svg.path-stroke-change-color path {
  stroke: #587EAD;
}
.button-critic:disabled {
  opacity: 0.5;
  color: #FFFFFF;
  background-color: #587EAD;
}
.button-critic:disabled svg {
  fill: #FFFFFF;
}
.button-critic:disabled svg.stroke-change-color {
  fill: none;
  stroke: #FFFFFF;
}
.button-critic:disabled svg.path-stroke-change-color {
  fill: none;
}
.button-critic:disabled svg.path-stroke-change-color path {
  stroke: #FFFFFF;
}
.button-critic:disabled:active,
.button-critic:disabled:hover {
  color: #FFFFFF;
  background-color: #587EAD;
}
.button-critic:disabled:active svg,
.button-critic:disabled:hover svg {
  fill: #FFFFFF;
}
.button-critic:disabled:active svg.stroke-change-color,
.button-critic:disabled:hover svg.stroke-change-color {
  fill: none;
  stroke: #FFFFFF;
}
.button-critic:disabled:active svg.path-stroke-change-color,
.button-critic:disabled:hover svg.path-stroke-change-color {
  fill: none;
}
.button-critic:disabled:active svg.path-stroke-change-color path,
.button-critic:disabled:hover svg.path-stroke-change-color path {
  stroke: #FFFFFF;
}
.button-critic svg {
  fill: #ED1C24;
}
.button-critic svg.stroke-change-color {
  fill: none;
  stroke: #ED1C24;
}
.button-critic svg.path-stroke-change-color {
  fill: none;
}
.button-critic svg.path-stroke-change-color path {
  stroke: #ED1C24;
}
.button-critic:hover,
.button-critic:focus {
  background: #ED1C24;
  color: #FFFFFF;
  border-color: #ED1C24;
}
.button-critic:hover svg,
.button-critic:focus svg {
  fill: #FFFFFF;
}
.button-critic:hover svg.stroke-change-color,
.button-critic:focus svg.stroke-change-color {
  fill: none;
  stroke: #FFFFFF;
}
.button-critic:hover svg.path-stroke-change-color,
.button-critic:focus svg.path-stroke-change-color {
  fill: none;
}
.button-critic:hover svg.path-stroke-change-color path,
.button-critic:focus svg.path-stroke-change-color path {
  stroke: #FFFFFF;
}
.button-critic:disabled {
  opacity: 0.8;
  border: 1.5px solid #DF9797;
  color: #DF9797;
  background: #FFFFFF;
  cursor: not-allowed;
}
.button-critic:disabled svg {
  fill: #DF9797;
}
.button-critic:disabled svg.stroke-change-color {
  fill: none;
  stroke: #DF9797;
}
.button-critic:disabled svg.path-stroke-change-color {
  fill: none;
}
.button-critic:disabled svg.path-stroke-change-color path {
  stroke: #DF9797;
}
.button-critic:disabled:active,
.button-critic:disabled:hover {
  opacity: 0.8;
  color: #DF9797;
  border: 1.5px solid #DF9797;
  background: #FFFFFF;
  cursor: not-allowed;
}
.button-critic:disabled:active svg,
.button-critic:disabled:hover svg {
  fill: #DF9797;
}
.button-critic:disabled:active svg.stroke-change-color,
.button-critic:disabled:hover svg.stroke-change-color {
  fill: none;
  stroke: #DF9797;
}
.button-critic:disabled:active svg.path-stroke-change-color,
.button-critic:disabled:hover svg.path-stroke-change-color {
  fill: none;
}
.button-critic:disabled:active svg.path-stroke-change-color path,
.button-critic:disabled:hover svg.path-stroke-change-color path {
  stroke: #DF9797;
}
.button-default {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 2px;
  height: 36px;
  padding: 9px 14px;
  color: white;
  background-color: #587EAD;
  border: 1.5px solid #B7C1D4;
  box-shadow: 0 1px 2px #E2E6EA;
  border-radius: 5px;
  transition: color;
  background: #FFFFFF;
  color: #587EAD;
  border: 1.5px solid #587EAD;
  box-shadow: 0 1px 2px rgba(226, 230, 234, 0.8);
}
.button-default > div {
  display: flex;
  align-items: center;
}
.button-default > svg:first-child:not(:last-child),
.button-default > span:first-child:not(:last-child),
.button-default > .action-icon:first-child:not(:last-child) {
  margin-left: -2px;
}
.button-default > svg + span,
.button-default > .anticon + span,
.button-default .action-icon + span {
  margin-left: 6px !important;
}
.button-default:last-child {
  text-align: center;
}
.button-default:active,
.button-default:focus {
  color: #FFFFFF;
  background-color: #587EAD;
}
.button-default:active svg,
.button-default:focus svg {
  fill: #FFFFFF;
}
.button-default:active svg.stroke-change-color,
.button-default:focus svg.stroke-change-color {
  fill: none;
  stroke: #FFFFFF;
}
.button-default:active svg.path-stroke-change-color,
.button-default:focus svg.path-stroke-change-color {
  fill: none;
}
.button-default:active svg.path-stroke-change-color path,
.button-default:focus svg.path-stroke-change-color path {
  stroke: #FFFFFF;
}
.button-default:hover {
  background: #FFFFFF;
  color: #587EAD;
  border: 1.5px solid #587EAD;
}
.button-default:hover svg {
  fill: #587EAD;
}
.button-default:hover svg.stroke-change-color {
  fill: none;
  stroke: #587EAD;
}
.button-default:hover svg.path-stroke-change-color {
  fill: none;
}
.button-default:hover svg.path-stroke-change-color path {
  stroke: #587EAD;
}
.button-default:disabled {
  opacity: 0.5;
  color: #FFFFFF;
  background-color: #587EAD;
}
.button-default:disabled svg {
  fill: #FFFFFF;
}
.button-default:disabled svg.stroke-change-color {
  fill: none;
  stroke: #FFFFFF;
}
.button-default:disabled svg.path-stroke-change-color {
  fill: none;
}
.button-default:disabled svg.path-stroke-change-color path {
  stroke: #FFFFFF;
}
.button-default:disabled:active,
.button-default:disabled:hover {
  color: #FFFFFF;
  background-color: #587EAD;
}
.button-default:disabled:active svg,
.button-default:disabled:hover svg {
  fill: #FFFFFF;
}
.button-default:disabled:active svg.stroke-change-color,
.button-default:disabled:hover svg.stroke-change-color {
  fill: none;
  stroke: #FFFFFF;
}
.button-default:disabled:active svg.path-stroke-change-color,
.button-default:disabled:hover svg.path-stroke-change-color {
  fill: none;
}
.button-default:disabled:active svg.path-stroke-change-color path,
.button-default:disabled:hover svg.path-stroke-change-color path {
  stroke: #FFFFFF;
}
.button-default svg {
  fill: #587EAD;
}
.button-default svg.stroke-change-color {
  fill: none;
  stroke: #587EAD;
}
.button-default svg.path-stroke-change-color {
  fill: none;
}
.button-default svg.path-stroke-change-color path {
  stroke: #587EAD;
}
.button-default-hover:active,
.button-default-hover:focus,
.button-default-hover:hover {
  background-color: #587EAD;
  border: 1.5px solid #B7C1D4;
  color: #FFFFFF;
  box-shadow: 0 1px 2px #E2E6EA;
}
.button-default-hover:active svg,
.button-default-hover:focus svg,
.button-default-hover:hover svg {
  fill: #FFFFFF;
}
.button-default-hover:active svg.stroke-change-color,
.button-default-hover:focus svg.stroke-change-color,
.button-default-hover:hover svg.stroke-change-color {
  fill: none;
  stroke: #FFFFFF;
}
.button-default-hover:active svg.path-stroke-change-color,
.button-default-hover:focus svg.path-stroke-change-color,
.button-default-hover:hover svg.path-stroke-change-color {
  fill: none;
}
.button-default-hover:active svg.path-stroke-change-color path,
.button-default-hover:focus svg.path-stroke-change-color path,
.button-default-hover:hover svg.path-stroke-change-color path {
  stroke: #FFFFFF;
}
.button-default-hover:disabled {
  opacity: 0.5;
  color: #B7C1D4;
  background: #FFFFFF;
}
.button-default-hover:disabled svg {
  fill: #B7C1D4;
}
.button-default-hover:disabled svg.stroke-change-color {
  fill: none;
  stroke: #B7C1D4;
}
.button-default-hover:disabled svg.path-stroke-change-color {
  fill: none;
}
.button-default-hover:disabled svg.path-stroke-change-color path {
  stroke: #B7C1D4;
}
.button-default-hover:disabled:active,
.button-default-hover:disabled:hover {
  color: #B7C1D4;
  background: #FFFFFF;
}
.button-default-hover:disabled:active svg,
.button-default-hover:disabled:hover svg {
  fill: #B7C1D4;
}
.button-default-hover:disabled:active svg.stroke-change-color,
.button-default-hover:disabled:hover svg.stroke-change-color {
  fill: none;
  stroke: #B7C1D4;
}
.button-default-hover:disabled:active svg.path-stroke-change-color,
.button-default-hover:disabled:hover svg.path-stroke-change-color {
  fill: none;
}
.button-default-hover:disabled:active svg.path-stroke-change-color path,
.button-default-hover:disabled:hover svg.path-stroke-change-color path {
  stroke: #B7C1D4;
}
.input {
  width: 100%;
  height: 36px;
  border-radius: 3px;
  border: 1px solid #CBD1E0;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  box-shadow: none;
}
.input::-webkit-input-placeholder {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  color: #959DAC;
  font-size: 14px;
  line-height: 18px;
  font-style: normal;
}
.input::-moz-placeholder {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  color: #959DAC;
  font-size: 14px;
  line-height: 18px;
  font-style: normal;
}
.input::placeholder {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  color: #959DAC;
  font-size: 14px;
  line-height: 18px;
  font-style: normal;
}
.input:hover,
.input:focus {
  border: 1px solid #3083FF;
  box-shadow: none;
}
.input-placeholder {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  color: #959DAC;
  font-size: 14px;
  line-height: 18px;
  font-style: normal;
}
.input-disabled {
  border: 1px solid #CBD1E0;
  background: #F8FAFD;
  color: #B7C1D5;
}
.input-disabled:hover,
.input-disabled:focus {
  border-color: #CBD1E0;
  background: #F8FAFD;
}
.ant-form-item-has-error .ant-input {
  border: 1px solid #ED1C24;
}
.ant-form-item-has-error:hover {
  background: transparent;
}
.ant-form-item-has-error .ant-input:focus,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-password:focus,
.ant-form-item-has-error .ant-input-password:hover {
  border-color: #ED1C24;
  box-shadow: none;
  background-color: transparent;
}
.ant-form-item-has-error .select-field {
  border-color: #ED1C24 !important;
}
.ant-form-item-has-error .select-field .caret-icon {
  color: #ED1C24;
}
.scroll {
  scrollbar-color: #B7C1D4 #F3F5F8;
  scrollbar-width: thin;
}
.scroll::-webkit-scrollbar {
  width: 20px;
  height: 20px;
}
.scroll::-webkit-scrollbar-track {
  border-radius: 9999px;
  background: #F3F5F8;
  border: 6px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}
.scroll::-webkit-scrollbar-thumb {
  border: 6px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #B7C1D4;
}
.modal-header .ant-modal-header {
  margin: 0 30px;
  padding: 0;
}
.modal-header .ant-modal-title {
  font-weight: 600;
  font-size: 16px;
  padding: 30px 0 16px;
}
.modal-header .ant-modal-close-x {
  position: relative;
}
.modal-header .ant-modal-body {
  padding: 16px 30px 30px;
}
.shadow {
  filter: drop-shadow(0px 0px 8px rgba(42, 43, 55, 0.15));
}
.auth-form {
  width: 600px;
  background-color: #3D608F;
  border: 1px solid #959DAC;
  border-radius: 8px;
  padding: 45px 0 90px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.auth-form h1 {
  text-align: center;
  color: #FFFFFF;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0;
}
.auth-form .ant-btn-primary {
  background-color: #FFFFFF;
  box-shadow: none;
  border-color: #B7C1D4;
  color: #587EAD;
}
.auth-form .ant-btn-primary:hover {
  border-color: #FFFFFF;
  background-color: #3D608F;
  color: #FFFFFF;
}
.auth-form .ant-form-item-control-input {
  margin-bottom: 2px;
  margin-top: 2px;
}
.auth-form input:-webkit-autofill,
.auth-form input:-webkit-autofill:hover,
.auth-form input:-webkit-autofill:focus,
.auth-form textarea:-webkit-autofill,
.auth-form textarea:-webkit-autofill:hover,
.auth-form textarea:-webkit-autofill:focus,
.auth-form select:-webkit-autofill,
.auth-form select:-webkit-autofill:hover,
.auth-form select:-webkit-autofill:focus {
  -webkit-transition-delay: 99999s;
}
.auth-fields {
  padding-top: 45px;
  width: 375px;
}
.auth-fields .ant-form-item {
  margin-bottom: 25px !important;
}
.auth-fields .ant-form-item .ant-form-item-explain {
  display: none;
}
.auth-fields .ant-form-item .ant-input-affix-wrapper {
  padding: 0;
  background-color: transparent;
  color: #FFFFFF !important;
}
.auth-fields .ant-form-item .ant-input-affix-wrapper .ant-input-suffix,
.auth-fields .ant-form-item .ant-input-affix-wrapper .ant-input-prefix {
  height: 100%;
}
.auth-fields .ant-form-item .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 10px;
}
.auth-fields .ant-form-item .ant-input-affix-wrapper .ant-input-suffix .anticon {
  color: #FFFFFF;
}
.auth-fields .ant-form-item .ant-input-affix-wrapper .ant-input-prefix {
  height: 100%;
  background-color: #FFFFFF;
  width: 65px;
  display: flex;
  justify-content: center;
  margin: 0;
  border-bottom-left-radius: 1px;
  border-top-left-radius: 1px;
}
.auth-fields .ant-form-item .ant-input-affix-wrapper .ant-input-prefix svg {
  color: #587EAD;
}
.auth-fields .ant-form-item .ant-input-affix-wrapper .ant-input,
.auth-fields .ant-form-item .ant-input-affix-wrapper input::placeholder {
  color: #FFFFFF;
}
.auth-fields .ant-form-item .ant-input-affix-wrapper .ant-input {
  padding-left: 25px;
}
.auth-tooltip .ant-tooltip-arrow-content,
.auth-tooltip .ant-tooltip-inner {
  background-color: #FFFFFF;
}
.auth-tooltip .ant-tooltip-inner {
  color: #ED1C24;
}
.auth-error {
  margin-bottom: 25px;
  background-color: #4B5D75;
  color: #FFFFFF;
  line-height: 36px;
  padding: 0 10px;
  border-radius: 5px;
  border: 1px solid #959DAC;
}
.auth-error .anticon {
  padding-right: 6px;
}
.flex-fields__card-title > .ant-card-head {
  margin-top: 32px;
  border-bottom: none;
  min-height: auto;
}
.flex-fields__card-title > .ant-card-head .ant-card-head-title {
  display: flex;
  font-size: 24px;
  font-weight: 400;
  padding: 0;
  line-height: 28px;
  color: #474444;
}
.flex-fields__card-title > .ant-card-head .ant-card-head-title:after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 1px;
  background-color: #CBD1E0;
  margin: auto 0 auto 16px;
}
.flex-fields__delete-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 2px;
  height: 36px;
  padding: 9px 14px;
  color: white;
  background-color: #587EAD;
  border: 1.5px solid #B7C1D4;
  box-shadow: 0 1px 2px #E2E6EA;
  border-radius: 5px;
  transition: color;
  border: 1.5px solid #ED1C24;
  color: #ED1C24;
  background: #FFFFFF;
}
.flex-fields__delete-btn > div {
  display: flex;
  align-items: center;
}
.flex-fields__delete-btn > svg:first-child:not(:last-child),
.flex-fields__delete-btn > span:first-child:not(:last-child),
.flex-fields__delete-btn > .action-icon:first-child:not(:last-child) {
  margin-left: -2px;
}
.flex-fields__delete-btn > svg + span,
.flex-fields__delete-btn > .anticon + span,
.flex-fields__delete-btn .action-icon + span {
  margin-left: 6px !important;
}
.flex-fields__delete-btn:last-child {
  text-align: center;
}
.flex-fields__delete-btn:active,
.flex-fields__delete-btn:focus {
  color: #FFFFFF;
  background-color: #587EAD;
}
.flex-fields__delete-btn:active svg,
.flex-fields__delete-btn:focus svg {
  fill: #FFFFFF;
}
.flex-fields__delete-btn:active svg.stroke-change-color,
.flex-fields__delete-btn:focus svg.stroke-change-color {
  fill: none;
  stroke: #FFFFFF;
}
.flex-fields__delete-btn:active svg.path-stroke-change-color,
.flex-fields__delete-btn:focus svg.path-stroke-change-color {
  fill: none;
}
.flex-fields__delete-btn:active svg.path-stroke-change-color path,
.flex-fields__delete-btn:focus svg.path-stroke-change-color path {
  stroke: #FFFFFF;
}
.flex-fields__delete-btn:hover {
  background: #FFFFFF;
  color: #587EAD;
  border: 1.5px solid #587EAD;
}
.flex-fields__delete-btn:hover svg {
  fill: #587EAD;
}
.flex-fields__delete-btn:hover svg.stroke-change-color {
  fill: none;
  stroke: #587EAD;
}
.flex-fields__delete-btn:hover svg.path-stroke-change-color {
  fill: none;
}
.flex-fields__delete-btn:hover svg.path-stroke-change-color path {
  stroke: #587EAD;
}
.flex-fields__delete-btn:disabled {
  opacity: 0.5;
  color: #FFFFFF;
  background-color: #587EAD;
}
.flex-fields__delete-btn:disabled svg {
  fill: #FFFFFF;
}
.flex-fields__delete-btn:disabled svg.stroke-change-color {
  fill: none;
  stroke: #FFFFFF;
}
.flex-fields__delete-btn:disabled svg.path-stroke-change-color {
  fill: none;
}
.flex-fields__delete-btn:disabled svg.path-stroke-change-color path {
  stroke: #FFFFFF;
}
.flex-fields__delete-btn:disabled:active,
.flex-fields__delete-btn:disabled:hover {
  color: #FFFFFF;
  background-color: #587EAD;
}
.flex-fields__delete-btn:disabled:active svg,
.flex-fields__delete-btn:disabled:hover svg {
  fill: #FFFFFF;
}
.flex-fields__delete-btn:disabled:active svg.stroke-change-color,
.flex-fields__delete-btn:disabled:hover svg.stroke-change-color {
  fill: none;
  stroke: #FFFFFF;
}
.flex-fields__delete-btn:disabled:active svg.path-stroke-change-color,
.flex-fields__delete-btn:disabled:hover svg.path-stroke-change-color {
  fill: none;
}
.flex-fields__delete-btn:disabled:active svg.path-stroke-change-color path,
.flex-fields__delete-btn:disabled:hover svg.path-stroke-change-color path {
  stroke: #FFFFFF;
}
.flex-fields__delete-btn svg {
  fill: #ED1C24;
}
.flex-fields__delete-btn svg.stroke-change-color {
  fill: none;
  stroke: #ED1C24;
}
.flex-fields__delete-btn svg.path-stroke-change-color {
  fill: none;
}
.flex-fields__delete-btn svg.path-stroke-change-color path {
  stroke: #ED1C24;
}
.flex-fields__delete-btn:hover,
.flex-fields__delete-btn:focus {
  background: #ED1C24;
  color: #FFFFFF;
  border-color: #ED1C24;
}
.flex-fields__delete-btn:hover svg,
.flex-fields__delete-btn:focus svg {
  fill: #FFFFFF;
}
.flex-fields__delete-btn:hover svg.stroke-change-color,
.flex-fields__delete-btn:focus svg.stroke-change-color {
  fill: none;
  stroke: #FFFFFF;
}
.flex-fields__delete-btn:hover svg.path-stroke-change-color,
.flex-fields__delete-btn:focus svg.path-stroke-change-color {
  fill: none;
}
.flex-fields__delete-btn:hover svg.path-stroke-change-color path,
.flex-fields__delete-btn:focus svg.path-stroke-change-color path {
  stroke: #FFFFFF;
}
.flex-fields__delete-btn:disabled {
  opacity: 0.8;
  border: 1.5px solid #DF9797;
  color: #DF9797;
  background: #FFFFFF;
  cursor: not-allowed;
}
.flex-fields__delete-btn:disabled svg {
  fill: #DF9797;
}
.flex-fields__delete-btn:disabled svg.stroke-change-color {
  fill: none;
  stroke: #DF9797;
}
.flex-fields__delete-btn:disabled svg.path-stroke-change-color {
  fill: none;
}
.flex-fields__delete-btn:disabled svg.path-stroke-change-color path {
  stroke: #DF9797;
}
.flex-fields__delete-btn:disabled:active,
.flex-fields__delete-btn:disabled:hover {
  opacity: 0.8;
  color: #DF9797;
  border: 1.5px solid #DF9797;
  background: #FFFFFF;
  cursor: not-allowed;
}
.flex-fields__delete-btn:disabled:active svg,
.flex-fields__delete-btn:disabled:hover svg {
  fill: #DF9797;
}
.flex-fields__delete-btn:disabled:active svg.stroke-change-color,
.flex-fields__delete-btn:disabled:hover svg.stroke-change-color {
  fill: none;
  stroke: #DF9797;
}
.flex-fields__delete-btn:disabled:active svg.path-stroke-change-color,
.flex-fields__delete-btn:disabled:hover svg.path-stroke-change-color {
  fill: none;
}
.flex-fields__delete-btn:disabled:active svg.path-stroke-change-color path,
.flex-fields__delete-btn:disabled:hover svg.path-stroke-change-color path {
  stroke: #DF9797;
}
.flex-fields__form-flex-row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  max-width: 380px;
  justify-content: flex-start;
}
.flex-fields__form-flex-row .ant-form-item {
  flex-grow: 1;
  flex-basis: 0;
  flex-shrink: 0;
  min-width: 0;
}
@media (max-width: 575px) {
  .flex-fields__form-flex-row .ant-form-item {
    min-width: 100%;
  }
}
.form-item__checkbox {
  margin-bottom: 0;
}
.form-flex {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.form-flex .ant-form-item-explain.ant-form-item-explain-error {
  margin-top: 6px;
}
.form-flex .ant-form-item-label > label {
  overflow: hidden;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.form-flex .ant-form-item {
  margin-bottom: 0;
  vertical-align: unset !important;
}
@media (max-width: 575px) {
  .form-flex .ant-form-item .ant-col {
    padding: 0px;
    flex: unset !important;
  }
}
.form-flex .ant-form-item .ant-input-number {
  width: 100%;
}
.form-flex .ant-form-item .ant-input-number-input {
  height: 34px;
}
.form-flex .form-flex-row {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  align-items: flex-end;
}
.form-flex .form-flex-row .ant-form-item {
  flex-grow: 1;
  flex-basis: 0;
  flex-shrink: 0;
  min-width: 0;
}
@media (max-width: 575px) {
  .form-flex .form-flex-row .ant-form-item {
    min-width: 100%;
  }
}
