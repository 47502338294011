@import 'src/themes/default/default';
@import 'src/shared/styles/mixins.less';

.pin-button {
  position: absolute;
  top: -6px;
  left: -16px;
  left: -16px;
  z-index: 4;
  .set-svg-color(@warning-dark);
}

.sticky-header {
  background: @background-faint-light;
  width: 100%;
  position: sticky;
  z-index: 10;

  &::before {
    content: "";
    height: 80px;
    background: @base-light;
    position: fixed;
    border-bottom: 1px solid @blue-100;
    top: 60px;
    right: 39px;

    @media screen and (min-width: 2073px) {
      background: @base-light;
      top: 28px;
    }
  }
}

.table-report {
  min-height: 100vh;

  &__wrapper {
    background-color: @white;
    border-radius: 10px;
    padding: 32px 24px;
    height: 100%;

    &_loading {
      min-height: 270px;


      .table-report__wrapper__spinner {
        margin: 50px 0px;
        margin: 50px 0px;
      }
    }
  }

  &__content {
    .ant-card-body {
      min-height: 100vh;

      .aside {
        height: fit-content;
        position: sticky;
        top: 136px;

        @media screen and (min-width: 1848px) {
          top: 80px;
        }
      }
    }

    .spinner-container {
      height: auto;
      min-height: 300px;
    }
  }

  &__description {
    font-weight: 400;
    font-size: large;
    margin-bottom: 0;
  }

  &__header {
    margin-bottom: 32px;
  }

  &__column {
    overflow-wrap: break-word;

    >span {
      display: flex;
      height: 100%;

      // --- align ---

      // default align
      justify-content: flex-start;
      align-items: center;
      text-align: center;

      &.left-top {
        align-items: flex-start;
        text-align: left;
      }

      &.left-middle {
        align-items: center;
        text-align: left;
      }

      &.left-bottom {
        align-items: flex-end;
        text-align: left;
      }

      &.right-top {
        justify-content: flex-end;
        align-items: flex-start;
        text-align: right;
      }

      &.right-middle {
        justify-content: flex-end;
        text-align: right;
      }

      &.right-bottom {
        justify-content: flex-end;
        align-items: flex-end;
        text-align: right;
      }

      &.middle-top {
        justify-content: center;
        align-items: flex-start;
      }

      &.middle-middle {
        justify-content: center;
      }

      &.middle-bottom {
        justify-content: center;
        align-items: flex-end;
      }

      // --- font-style ---

      &.bold {
        font-weight: 800;
      }

      &.cursive {
        font-style: italic;
      }

      &.underline {
        text-decoration: underline;
      }

      &.bold-cursive {
        font-weight: 800;
        font-style: italic;
      }

      &.bold-underline {
        font-weight: 800;
        text-decoration: underline;
      }

      &.cursive-underline {
        font-style: italic;
        text-decoration: underline;
      }

      &.bold-underline-cursive {
        font-weight: 800;
        font-style: italic;
        text-decoration: underline;
      }

      &.monospace {
        font-family: monospace, monospace;
      }

      // --- custom-columns ---
      &.custom-column-icon {
        svg {
          width: 16px;
        }
      }
    }

    &.drilldown-link {
      color: @text-complement-light;
      text-decoration: underline;
      cursor: pointer;
    }

    .text-bold {
      font-weight: 700;
    }

    .text-positive {
      color: @green-600;
    }

    .text-negative {
      color: @red-600;
    }

    .text-blue {
      color: @link-complement-light;
    }
  }

  &__table {
    .ant-table {
      border: 0 1px solid @blue-100;

      // &-pagination {
      //   position: sticky;
      //   bottom: 10px;
      //   margin-bottom: 0px;

      //   .ant-pagination-item-link, .ant-pagination-options-quick-jumper {
      //     background: white;
      //   }
      // }
    }

    &_pinned {
      .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content table thead.ant-table-thead .ant-table-cell {
        background-color: @faint-light-up;
      }
    }

    &__filter-icon {
      display: inline-block;

      &>svg {
        margin-bottom: -2px;
        margin-left: 10px;
      }
    }
  }

  & .ant {
    &-card {
      min-width: 600px;

      &-head {
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 1;
        padding: 0;
        border-bottom: 2px solid @gray-200;

        &-title {
          padding: 0;
        }
      }

      &-body {
        display: flex;
        padding: 0;

        &::before,
        ::after {
          display: none;
        }
      }
    }

    &-form:not(.search):not(.table-report-filter-form):not(.comparison-form__form) {
      width: 400px;
    }

    &-input-affix-wrapper-lg {
      padding: 5px 11px;
    }

    &-table {
      &-content {
        margin: 0 15%;
      }

      &-thead>tr>th {
        padding: 10px 6px;
        text-align: center;
        font-weight: 600;
        line-height: 16px;
        text-transform: uppercase;
        color: @faint-dark !important;
      }

      table {
        height: fit-content;
        table-layout: auto !important;
      }

      & tbody>tr>td {
        height: 100%;
        padding: 12px;

        div {
          height: 100%;
        }
      }

      &-wrapper {
        flex-grow: 1;
        flex-shrink: 1;
        width: auto;
        min-width: unset !important;

        &.post-card {
          padding: 0;
        }
      }

      &-content {
        margin: 0;
      }
    }

    &-divider {
      border-color: @line-faint-light;

      &_large {
        border-width: 2px;
      }

      &_small {
        border-width: 1px;
        height: 24px !important;
      }

      &_middle {
        top: 0;
        border-width: 2px;
        height: 68px !important;
      }
    }

    &-space-item {
      & svg {
        &:hover {
          cursor: pointer;
          color: @blue-600;
        }
      }

      & .menu__item {
        cursor: pointer;

        &:hover,
        :focus {
          color: @blue-600;
        }
      }
    }

    &-btn-icon-only {
      border: unset;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";