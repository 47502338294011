@import 'src/themes/default/default';

.custom-tabs {
  display: flex;
  border-radius: 5px;
  flex-direction: row;
  border: 1px solid @blue-100;

  &__body {
    flex-grow: 1;
    padding: 24px;
    // overflow: hidden;
    min-width: 0;

    &__item {
      &__title {
        display: flex;
        font-size: 24px;
        font-weight: 400;
        padding: 0;
        line-height: 28px;
        color: @text-accent-dark;
        margin: 0 0 26px 0;

        &::after {
          content: '';
          display: inline-block;
          flex-grow: 1;
          height: 1px;
          background-color: @blue-100;
          margin: auto 0 auto 16px;
        }
      }


      &_invisible {
        display: none;
      }
    }
  }

  &:not(&_with-up-menu) {

    // стили для body, если меню слева
    .custom-tabs__menu {
      border-right: 1px solid #f0f0f0;
      padding: 16px;
      width: 276px;
      flex-shrink: 0;
      font-size: 16px;

      &__tab {
        color: @faint-dark;
        padding: 12px 7px;
        cursor: default;

        &:not(&__selected) {
          &:hover {
            color: @base-dark;
          }
        }

        &:hover {
          cursor: pointer;
        }

        &__selected {
          color: @active-tab-light;
          font-weight: 500;
          display: flex;
          align-items: center;

          &:hover {
            cursor: default;
          }

          &::after {
            content: '';
            background-color: @active-tab-light;
            position: inherit;
            margin: auto 12px;
            width: 4px;
            height: 4px;
            border-radius: 9999px;
            flex-shrink: 0;
          }
        }

        &.disabled{
          opacity: .5;
          &:hover {
            cursor: not-allowed;
            user-select: none;
          }
        }
      }
    }
  }



  &_with-up-menu {
    // другие стили для body, если меню вверху
    flex-direction: column;
    border: 0;

    .custom-tabs__body {
      padding: 0;
      padding-top: 24px;

      &__item__title {
        display: none;
      }
    }

    .custom-tabs__menu {
      border: 0;
      display: flex;
      border-bottom: 1px solid #f0f0f0;
      flex-wrap: wrap;
      font-size: 16px;

      &__tab {
        cursor: pointer;
        user-select: none;

        &:not(&__selected) {
          &:hover {
            opacity: .85;
          }
        }

        padding: 10px 16px;
        border: 1px solid transparent;

        &__is-hidden {
          display: none;
        }

        &__selected {
          font-weight: 500;
          border: 1px solid #f0f0f0;
          border-top-right-radius: 5px;
          border-top-left-radius: 5px;
          border-bottom: 1px solid white;
          margin-bottom: -1px;
        }

      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";