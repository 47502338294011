@import 'src/themes/default/default';

.header {
  min-height: 60px;
  width: auto;
  background-color: @accent-dark;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px 0 72px;
  position: sticky;
  top: 0;
  z-index: 99;

  &__nav {
    display: flex;

    &__links {
      display: flex;
      gap: 9.46px;
      align-items: center;
    }

    &__link {
      color: inherit;
      line-height: 0;

      & svg {
        width: auto;
        height: 24px;

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  &__menu {
    font-size: 21px;
    color: @base-light;
    gap: 22.12px;
    display: flex;
    align-items: center;

    &__exit-icon {
      color: @base-light;
      cursor: pointer;
    }

    &__request {
      background: @complement-light;

      font-size: 16px;
      margin: 0;
      font-family: 'Roboto', sans-serif;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      height: 32px;
      width: 129px;
      padding: 9px 10px;
      color: @base-light !important;
      border: 1.5px solid @base-light;
      border-radius: 5px;
      transition: color;

      &:hover {
        opacity: 0.5;
      }

      &__icon {
        opacity: 1;
        margin-top: 2px;
        transition: opacity ease-in 0.3s;

        &:hover {
          opacity: 0.5;
        }
      }
    }

    &__container {
      position: relative;
      display: flex;
      flex-direction: column;
    }

    &__activeLink {
      position: absolute;
      width: 8px;
      height: 8px;
      left: 70%;
      border-radius: 50%;
      background: yellow;
      box-shadow: 0 0 2px 2px yellow;
    }

    &__profile,
    &__icon {
      cursor: pointer;
      color: @icon-dark;

      &:last-child {
        margin-right: 0;
      }
    }

    &__profile {
      display: flex;
      align-items: center;
      height: 40px;

      font-size: 14px;
      color: black;

      &__avatar {
        width: 35px;
        height: 35px;
        color: white;
        display: flex;
        gap: 24px;
        font-size: large;
        font-weight: 300;
        border-radius: 50%;
        position: relative;
        align-items: center;
        justify-content: center;
        background-color: @complement-dark;
      }
    }

    &__links {
      display: flex;
      align-items: baseline;
    }

    .modal-wrapper {
      .alert {
        width: 35px;
        height: 35px;
      }
    }

    &__link {
      color: inherit;
      line-height: 0;

      &__request {
        width: 130px;
        height: 33px;
        display: flex;
        flex-direction: row;
        border: 1px solid white;
        border-radius: 5.5px;
        align-items: center;
        justify-content: center;
        color: white;
        background-color: @complement-light;

        &__icon {
          opacity: 1;
          transition: opacity ease-in 0.3s;
          width: 21px;
          height: 21px;
        }

        svg {
          margin: auto;
          width: 30%;
        }

        span {
          width: 70%;
          text-align: start;
          font-size: 15px;
          font-weight: 400;
        }
      }
    }

    &__icon {
      opacity: 1;
      transition: opacity ease-in 0.3s;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";