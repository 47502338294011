@import "./src/themes/default/default";
@import "./../../shared/styles/mixins";

.property-settings {

  .ant-layout-sider-children {
    .ant-menu-inline-collapsed {
      .ant-menu-item {
        padding-left: 32px !important;
      }
    }
  }

  .spinner-container {
    margin-top: 10%;
  }

  .ant-menu-item {
    a {
      color: @base-dark !important;
    }

    &:after {
      border-right: none !important;
    }

    &.ant-menu-item-selected {
      &:after {
        content: '';
        background-color: @active-tab-light;
        position: inherit;
        margin: auto 12px;
        width: 4px;
        height: 4px;
        border-radius: 9999px;
        transform: matrix(1, 0, 0, -1, 0, 0);
        animation: select-animate .2s linear;
      }
    }
  }

}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";