@import "src/themes/default/default";

.auth-page {
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../shared/assets/auth-bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: @auth-background-overlay-color;
    background-blend-mode: overlay;
    filter: @auth-background-filter;
    z-index: 1;
  }

  &__partner-logo {
    margin-left: 42px;
    padding-left: 42px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 52px;
      border-left: 1px solid @base-light;
    }

    img {
      width: @auth-partner-logo-width;
      height: @auth-partner-logo-height;
    }
  }

  &-logo {
    position: absolute;
    top: 70px;
    left: 70px;
    display: flex;
    align-items: center;
    z-index: 2;

    &__icon {
      width: 92px;
      height: 92px;
    }

    &__name {
      font-family: Montserrat, sans-serif;
      margin-left: 26px;
      color: @white;
      font-size: 18px;
      letter-spacing: 2px;
      user-select: none;
    }
  }

  .ant-spin-dot {
    z-index: 2; // Контент поверх затемняющего слоя

    &-item {
      background-color: @white !important;
    }
  }

  .ant-spin-text {
    color: @white !important;
    position: relative;
    z-index: 2; // Контент поверх затемняющего слоя
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";