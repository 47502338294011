@import 'src/shared/styles/mixins.less';

.report-ks-settings {
  .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 8px;

    > div {
      width: 100%;
    }
  }

  &__header-row {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 8px;

    margin-bottom: 24px;
  }

  &__remove-btn {
    height: 36px;
    width: 36px;

    margin-bottom: 25px;
  }
}

@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";